@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat:ital,wght@0,300;

0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Orbitron:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Orbitron:wght@700&display=swap");
body {
  overflow-x: hidden;
}

.wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

._small-container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 15px;
}

._container {
  max-width: 1330px;
  margin: 0 auto;
  padding: 0 15px;
}

._ibg {
  position: relative;
}

._ibg img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
}

.page {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  font-family: "Montserrat";
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}


._swiper {
  overflow: hidden;
}

._swiper .swiper-wrapper {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

._swiper.swiper-container-vertical .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

._swiper.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.swiper-container-initialized .swiper-slide {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.swiper-container-android .swiper-slide,
.swiper-container-android .swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
          transform: translate3d(0px, 0, 0);
}

.swiper-button-lock {
  display: none !important;
}

.range {
  background: -webkit-gradient(linear, left top, right top, from(#57B8FF), color-stop(40%, #57B8FF), color-stop(40%, #fff), to(#fff));
  background: -o-linear-gradient(left, #57B8FF 0%, #57B8FF 40%, #fff 40%, #fff 100%);
  background: linear-gradient(to right, #57B8FF 0%, #57B8FF 40%, #fff 40%, #fff 100%);
  border-radius: 0;
  height: 8px;
  width: 324px;
  overflow: hidden;
}

.slider {
  background: white;
  border-radius: 0px;
  width: 324px;
}

.field {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

.field input {
  -webkit-appearance: none;
  height: 8px;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#57B8FF), color-stop(0%, #57B8FF), color-stop(0%, #E3E3E3), to(#E3E3E3));
  background: -o-linear-gradient(left, #57B8FF 0%, #57B8FF 0%, #E3E3E3 0%, #E3E3E3 100%);
  background: linear-gradient(to right, #57B8FF 0%, #57B8FF 0%, #E3E3E3 0%, #E3E3E3 100%);
  border-radius: 0px;
  outline: none;
  border: none;
}

.field input::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 30px;
  width: 30px;
  border: 6px solid #1583D3;
  background: white;
  border-radius: 50%;
}

.field input::-moz-range-thumb {
  -moz-appearance: none;
  border: none;
  height: 30px;
  width: 30px;
  border: 3px solid white;
  background: darkblue;
  border-radius: 50%;
}

.header {
  padding: 27px 0 18px;
}

.header__logo {
  width: 113px;
  height: 50px;
  position: relative;
  z-index: 5;
}

.header__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 10px;
}

.menu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 33px;
}

.menu__item:last-child {
  margin-left: 30px;
}

.menu__link {
  font-weight: 500;
  font-size: 22px;
  line-height: 140.4%;
  color: #2E1AAE;
}

.menu__link:hover {
  text-decoration: underline;
}

.icon-menu {
  display: none;
}

.menu-mobile {
  display: none;
}

.menu-mobile._active {
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  top: 0;
}

.menu-mobile__content {
  padding-top: 90px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.menu-mobile__top {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
}

.menu-mobile__bottom {
  padding: 0 10px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
}

.menu-mobile__bottom-content {
  background: #2E1AAE;
  border-radius: 10px 10px 0 0;
  height: 100%;
}

.menu-mobile__bottom-block {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 3px;
}

.menu-mobile__bottom-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 186%;
  color: #FFFFFF;
}

.menu-mobile__mail {
  font-weight: 500;
  font-size: 12px;
  line-height: 167%;
  color: #FFFFFF;
}

.menu-mobile__tg {
  font-weight: 500;
  font-size: 12px;
  line-height: 167%;
  color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.menu-mobile__top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 9px;
}

.menu-mobile__link {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
  padding-left: 25px;
}

.menu-mobile__link.active {
  color: #5A57C2;
  position: relative;
}

.menu-mobile__link.active::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 9px;
  height: 30px;
  background: #2E1AAE;
  border-radius: 0px 5px 5px 0px;
}

.menu-mobile__logout {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #5A57C2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: transparent;
  padding-left: 25px;
}

.main-block__block {
  background: #2E1AAE;
  border-radius: 85px;
}

.main-block__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.main-block__range-block {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px 0px;
  position: relative;
  top: 86px;
}

.main-block__range-top {
  padding: 56px 65px 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 30px;
  position: relative;
  z-index: 3;
}

.main-block__range-label {
  font-weight: 700;
  font-size: 22px;
  line-height: 110%;
  color: #4F4F4F;
  margin-bottom: 18px;
}

.main-block__range-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  color: #4F4F4F;
  margin-top: 12px;
}

.main-block__btn {
  margin: 9px auto 0;
  background: #57B8FF;
  -webkit-box-shadow: 0px 0px 1px rgba(27, 29, 34, 0.02), 0px 1px 1px rgba(27, 29, 34, 0.1);
          box-shadow: 0px 0px 1px rgba(27, 29, 34, 0.02), 0px 1px 1px rgba(27, 29, 34, 0.1);
  border-radius: 6px;
  gap: 8px;
  width: 233px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.main-block__btn:hover {
  background: rgba(87, 184, 255, 0.5);
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.main-block__btn:active {
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(27, 29, 34, 0.02), 0px 0px 0px 3px rgba(87, 184, 255, 0.2);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(27, 29, 34, 0.02), 0px 0px 0px 3px rgba(87, 184, 255, 0.2);
}

.main-block__range-bottom {
  padding: 29px 40px 32px;
  background: #ECF6FF;
  border-radius: 0px 0px 23px 0px;
}

.main-block__range-flex-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
}

.main-block__range-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #4F4F4F;
}

.main-block__range-flex span:last-child {
  font-weight: 600;
}

.main-block__range-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4F4F4F;
  margin-top: 40px;
  max-width: 375px;
}

.main-block__range-text a {
  color: #39CCE0;
}

.main-block__text-block {
  padding: 113px 0 174px;
  max-width: 497px;
  top: 86px;
}

.main-block__big-text {
  font-weight: 800;
  font-size: 50px;
  line-height: 140.4%;
  color: #FFFFFF;
  position: relative;
}

.main-block__big-text .up {
  position: absolute;
  top: 58px;
  left: -44%;
}

.main-block__big-text .down {
  display: none;
}

.main-block__small-text {
  font-weight: 800;
  font-size: 22px;
  line-height: 140.4%;
  color: rgba(255, 255, 255, 0.85);
  margin-top: 26px;
}

.money-block {
  margin-top: 152px;
}

.money-block .money-block__content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.money-block .money-block__img {
  width: 100%;
}

.money-block .money-block__img img {
  width: 100%;
}

.money-block .money-block__text-block {
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  color: #4F4F4F;
}

.money-block .money-block__title {
  font-weight: 800;
  font-size: 38px;
  color: #4F4F4F;
  line-height: 105%;
}

.money-block .money-block__title span {
  color: #5A57C2;
}

.money-block .money-block__text {
  margin: 20px 0 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
}

.money-block .money-block__card-block {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 430px;
  gap: 28px;
}
@media (max-width: 1100px) {
  .money-block .money-block__card-block{
    height: 500px;
    gap: 18px;
    max-width: 525px;
  }
  .money-block .money-block__card-text{
    max-width: 200px!important;
  }
}
@media (max-width: 990.98px) {
  .money-block .money-block__card-block{
    height: 500px;
    gap: 18px;
    max-width: 100%;
  }
  .money-block .money-block__card-text{
    max-width:300px!important;
  }
}
@media (max-width: 767.98px) {
  .money-block .money-block__card-block{
    height: 500px;
    gap: 18px;
    max-width: 100%;
  }
  .money-block .money-block__card-text{
    max-width:200px!important;
  }
}
@media (max-width: 574.98px) {
  .money-block .money-block__card-block{
    height: 300px;
    gap: 9px;
    max-width: 100%;
  }
  .money-block .money-block__card{
    max-width: 50%;
  }
}
.money-block .money-block__card {
  background: #F7F8F9;
  border-radius: 10px;
  padding: 15px;
}

.money-block .money-block__card-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 140.4%;
  color: #2E1AAE;
}

.money-block .money-block__card-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #4F4F4F;
  max-width: 221px;
  margin-top: 25px;
}

.text-block {
  margin-top: 155px;
}

.text-block .text__block-background {
  background: #ECF6FF;
  border-radius: 45px 0px 0px 45px;
  margin-left: 13%;
}

.text-block .text__block-background .text-block__container {
  max-width: 1130px;
  padding: 89px 15px 31px;
}

.text-block .text__block-background .text-block__container .text-block__title {
  max-width: 392px;
  font-weight: 800;
  font-size: 38px;
  line-height: 105%;
  color: #4F4F4F;
  margin-left: auto;
  position: relative;
  z-index: 1;
  margin-right: 34px;
}

.text-block .text__block-background .text-block__container .text-block__title span {
  color: #5A57C2;
}

.text-block .text__block-background .text-block__container .text-block__title img {
  position: absolute;
  left: 58%;
  top: 15px;
  z-index: -1;
}

.text-block .text__block-background .text-block__section {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 35px;
  padding-left: 70px;
  position: relative;
  z-index: 1;
}

.text-block .text__block-background .text-block__section .text-block__block {
  color: #4F4F4F;
  max-width: 250px;
}

.text-block .text__block-background .text-block__section .text-block__block .text-block__block-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
}

.text-block .text__block-background .text-block__section .text-block__block .text-block__block-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 121.69%;
  margin-top: 11px;
  max-width: 223px;
}

.accordion-block {
  margin: 170px 0;
  position: relative;
}

.accordion-block .accordion-block__decor {
  background: #2E1AAE;
  border-radius: 0px 45px 45px 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: -90%;
}

.accordion-block .accordion-block__img {
  position: absolute;
  bottom: 0;
  right: 20px;
}

.accordion-block .accordion-block__content .accordion-block__block {
  padding-left: 67px;
  padding-right: 48px;
}

.accordion-block .accordion-block__content .accordion-block__title {
  font-weight: 800;
  font-size: 38px;
  line-height: 105%;
  color: #4F4F4F;
}

.accordion-block .accordion-block__content .accordion-block__accordions {
  margin-top: 38px;
  display: -ms-grid;
  cursor: pointer;
  column-count: 2;
  column-gap: 25px;
}

.accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item {
  background: #F7F8F9;
  border-radius: 10px;
  padding: 18px 28px;
  overflow: hidden;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  margin-bottom: 31px;
}
.accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item:hover {
  -webkit-box-shadow: 0px 0px 3px 3px #39CCE0;
          box-shadow: 0px 0px 3px 3px #39CCE0;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item.active .accordion-block__item-text div {
  display: block;
}

.accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item.active .accordion-block__item-text p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 15px;
}

.accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item .accordion-block__item-title {
  font-weight: 800;
  font-size: 22px;
  line-height: 120%;
  color: #5A57C2;
}

.accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item .accordion-block__item-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 133%;
  color: #4F4F4F;
}

.accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item .accordion-block__item-text ul {
  padding-left: 30px;
}

.accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item .accordion-block__item-text ul li {
  list-style: disc !important;
}

.accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item .accordion-block__item-text div {
  margin-top: 10px;
  display: none;
}

.accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item .accordion-block__item-text p {
  margin-top: 10px;
  display: none;
}

.footer {
  padding: 0 15px;
}

.footer ._small-container {
  padding: 0;
}

.footer a:hover {
  color: #39CCE0;
}

.footer .footer__container {
  background: #2E1AAE;
  border-radius: 85px 85px 0px 0px;
}

.footer .footer__container .footer__block {
  padding-top: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer .footer__container .footer__left .footer__text-block {
  margin-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 19px;
  max-width: 582px;
}

.footer .footer__container .footer__left .footer__text-block .footer__text-link {
  font-weight: 500;
  font-size: 18px;
  line-height: 111%;
  color: #FFFFFF;
}

.footer .footer__container .footer__left .footer__text-block .footer__text-link:hover {
  color: #39CCE0;
}

.footer .footer__container .footer__left-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 29px;
}

.footer .footer__container .footer__left-link {
  font-weight: 700;
  font-size: 18px;
  line-height: 144%;
  color: #FFFFFF;
}

.footer .footer__container .footer__left-link:hover {
  color: #39CCE0;
}

.footer .footer__container .footer__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.footer .footer__container .footer__right .footer__right-link {
  font-weight: 700;
  font-size: 18px;
  line-height: 144%;
  color: #FFFFFF;
}

.footer .footer__container .footer__right .footer__right-link:hover {
  color: #39CCE0;
}

.footer .footer__container .footer__right .mail {
  font-weight: 500;
  font-size: 18px;
  line-height: 134%;
  color: #FFFFFF;
}

.footer .footer__container .footer__right .mail:hover {
  color: #39CCE0;
}

.footer .footer__container .footer__right .tg {
  margin-top: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 134%;
  color: #FFFFFF;
}

.footer .footer__container .footer__right .tg:hover {
  color: #39CCE0;
}

.footer .footer__center {
  margin-top: 27px;
}

.footer .footer__center .footer__center-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 144%;
  color: #FFFFFF;
}

.footer .footer__center .footer__center-items {
  margin-top: 11px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.footer .footer__center .footer__center-link {
  font-weight: 500;
  font-size: 18px;
  line-height: 111%;
  color: #FFFFFF;
}

.footer .footer__center .footer__center-link:hover {
  color: #39CCE0;
}

.footer .footer__bottom {
  padding: 40px 0 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 13px;
}

.footer .footer__bottom .footer__bottom-text {
  max-width: 608px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
}

@media (max-width: 1250px) {
  .accordion-block .accordion-block__decor {
    display: none;
  }

  .accordion-block .accordion-block__img {
    display: none;
  }

  .accordion-block .accordion-block__content .accordion-block__block {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 990.98px) {
  .main-block__block {
    padding: 0 0 21px;
  }

  .main-block__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .main-block__range-block {
    top: 0;
  }

  .main-block__text-block {
    padding: 34px 0 35px;
    -ms-flex-item-align: end;
        align-self: flex-end;
  }

  .money-block {
    margin-top: 39px;
  }

  .money-block .money-block__content {
    position: relative;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .money-block .money-block__img {
    position: absolute;
    top: 0;
    right: 0;
    width: 154px;
    height: 236.07px;
  }

  .money-block .money-block__text-block {
    position: relative;
  }

  .money-block .money-block__title {
    position: relative;
  }

  .money-block .money-block__title:first-child {
    width: 80%;
  }

  .money-block .money-block__text {
    width: 80%;
  }

  .text-block .text__block-background {
    margin-left: 5%;
  }

  .text-block .text__block-background .text-block__section {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    padding-left: 40px;
  }

  .footer .footer__container .footer__left-links {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .footer .footer__center .footer__center-items {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767.98px) {
  .header {
    padding: 9px 0 9px;
  }

  .menu__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .menu__item:last-child {
    margin-left: 0px;
  }

  .icon-menu {
    display: block;
    position: relative;
    width: 26px;
    height: 16px;
    cursor: pointer;
    z-index: 5;
  }

  .icon-menu span {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    top: calc(50% - 1px);
    left: 0px;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #2E1AAE;
  }

  .icon-menu span:first-child {
    top: 0px;
  }

  .icon-menu span:last-child {
    top: auto;
    bottom: 0px;
  }

  .icon-menu._active span {
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
  }

  .icon-menu._active span:first-child {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: calc(50% - 1px);
  }

  .icon-menu._active span:last-child {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: calc(50% - 1px);
  }

  .menu__body {
    display: none;
  }

  .menu-mobile {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    background: #FFFFFF;
    z-index: 4;
    top: -200%;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
  }

  .main-block__range-block {
    top: 0;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }

  .main-block__text-block {
    padding: 14px 0 15px;
    font-weight: 800;
    font-size: 16px;
    line-height: 140.4%;
  }

  .main-block__big-text {
    font-weight: 800;
    font-size: 26px;
    line-height: 140.4%;
  }

  .main-block__big-text .up {
    display: none;
  }

  .main-block__big-text .down {
    display: block;
    position: absolute;
    top: 30px;
    left: -53%;
    width: 326px;
  }

  .main-block__small-text {
    display: none;
  }

  .accordion-block .accordion-block__content .accordion-block__accordions {
    column-count: 1;
  }
  .accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item{
    margin-bottom: 10px;
  }
  .footer .footer__container {
    border-radius: 10px;
  }

  .footer .footer__container .footer__block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 27px;
    padding-top: 20px;
  }

  .footer .footer__container .footer__left .footer__text-block {
    margin-top: 11px;
    gap: 11px;
  }

  .footer .footer__container .footer__left .footer__text-block .footer__text-link {
    font-size: 14px;
  }

  .footer .footer__container .footer__left-links {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 5px;
  }

  .footer .footer__container .footer__left-link {
    font-size: 14px;
  }

  .footer .footer__container .footer__right {
    font-size: 14px;
  }

  .footer .footer__container .footer__right .footer__right-link {
    font-size: 14px;
  }

  .footer .footer__container .footer__right .mail {
    font-size: 14px;
  }

  .footer .footer__container .footer__right .tg {
    font-size: 14px;
  }

  .footer .footer__center .footer__center-title {
    font-size: 14px;
  }

  .footer .footer__center .footer__center-items {
    margin-top: 0;
  }

  .footer .footer__center .footer__center-link {
    font-size: 14px;
  }

  .footer .footer__bottom {
    padding: 27px 0 38px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .footer .footer__bottom .footer__bottom-text {
    font-size: 12px;
  }

  .footer .footer__bottom .footer-visa {
    margin-top: 14px;
  }
}

@media (max-width: 574.98px) {
  .header__logo {
    width: 68px;
    height: 30px;
  }
  .header__logo svg{
    width: 68px;
    height: 30px;
  }
  .range {
    width: 100%;
  }

  .slider {
    width: 100%;
  }

  .field input {
    height: 5.54px;
  }

  .field input::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border: 4px solid #1583D3;
  }

  .main-block__block {
    border-radius: 10px;
  }

  .main-block ._small-container {
    padding: 0;
  }

  .main-block__range-block {
    -webkit-box-shadow: none;
            box-shadow: none;
    border-radius: 0;
  }

  .main-block__range-top {
    padding: 25px 40px 26px;
    gap: 10px;
  }

  .main-block__range-label {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .main-block__range-value {
    font-size: 10px;
  }

  .main-block__btn {
    width: 172px;
    height: 36px;
    font-size: 12px;
  }

  .main-block__range-bottom {
    border-radius: 0px 0px 0px 0px;
    padding: 8px 40px 1px;
  }

  .main-block__range-flex-column {
    gap: 4px;
  }

  .main-block__range-flex {
    font-size: 12px;
  }

  .main-block__range-text {
    margin-top: 14px;
    font-size: 10px;
    margin-bottom: 14px;
  }

  .main-block__big-text {
    font-size: 16px;
    right: 30px;
  }

  .main-block__big-text .down {
    width: 227px;
    top: 18px;
    left: -62%;
  }

  .money-block .money-block__text-block {
    position: relative;
    font-size: 14px;
  }

  .money-block .money-block__title {
    position: relative;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
  }

  .money-block .money-block__title:first-child {
    width: 100%;
  }

  .money-block .money-block__text {
    width: 60%;
  }

  .money-block .money-block__card-block {
    margin-top: 20px;
    gap: 9px;
  }

  .money-block .money-block__card {
    padding: 9px 9px 14px;
  }

  .money-block .money-block__card-title {
    font-size: 12px;
  }

  .money-block .money-block__card-text {
    font-size: 10px;
    margin-top: 11px;
  }

  .text-block {
    margin-top: 0;
  }

  .text-block .text__block-background .text-block__container {
    padding: 26px 15px 36px;
  }

  .text-block .text__block-background .text-block__container .text-block__title {
    font-size: 16px;
    max-width: 166px;
  }

  .text-block .text__block-background .text-block__container .text-block__title img {
    width: 81px;
  }

  .text-block .text__block-background .text-block__section {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 6px;
    padding-left: 0;
  }

  .text-block .text__block-background .text-block__section .text-block__block .text-block__block-title {
    font-size: 14px;
  }

  .text-block .text__block-background .text-block__section .text-block__block .text-block__block-text {
    font-size: 12px;
    margin-top: 6px;
    max-width: 200px;
  }

  .accordion-block {
    margin: 35px 0;
  }

  .accordion-block .accordion-block__content .accordion-block__title {
    font-size: 16px;
  }

  .accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item {
    padding: 12px 10px 17px;
  }

  .accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item.active .accordion-block__item-text p {
    gap: 10px;
  }

  .accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item .accordion-block__item-title {
    font-size: 14px;
  }

  .accordion-block .accordion-block__content .accordion-block__accordions .accordion-block__item .accordion-block__item-text {
    font-size: 12px;
    margin-top: 8px;
  }

  .footer .footer__center .footer__center-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 2px;
  }
}
.article ._small-container{
  padding: 0;
}
.about-us ._small-container{
  padding: 0;
}
.question ._small-container{
  padding: 0;
}
.document ._small-container{
  padding: 0;
}
.contact ._small-container{
  padding: 0;
}
.document__content{
  padding: 31px 0 271px!important;
  max-width: 740px!important;
}
.section-top{
  height: 260px;
  background: #2E1AAE;
  border-radius: 85px;
  padding: 86px 10px 0;
}
.section-top__content{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.section-top__bread-crumbs{
  display: flex;
  align-items: center;
  gap: 4px;
}
.section-top__bread-crumbs a{
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #F2F2F2;

}
.section-top__bread-crumbs span{
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #8EE4E6;
}
.section-top__title{
  margin-top: 34px;
  font-weight: 800;
  font-size: 38px;
  line-height: 105%;
  color: #FFFFFF;
}
.section-top__left{
  max-width: 595px
}
.section-top__right .main-block__range-block{
  top: 0;
}
.section-top__right{
  width: 458px
}
.article{
  min-height: 800px;
}
.question{
  min-height: 800px;
}
.about-us{
  min-height: 800px;

}

.article__content{
  padding: 31px 0 196px;
}
.article__text{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 541px;

}

.article__text p {
    margin:0;
    padding: 0;
}


.article__text  h1, .accordion-block__item-text h1 {
    color: #4F4F4F;
    font-size: 40px;
}
.article__text h2 .accordion-block__item-text h2 {
    color: #4F4F4F;
    font-size: 30px;
}
.article__text h3 .accordion-block__item-text h3 {
    color: #4F4F4F;
    font-size: 25px;
}
.article__text h4 .accordion-block__item-text h4 {
    color: #4F4F4F;
    font-size: 20px;
}
.article__text h5 .accordion-block__item-text h5 {
    color: #4F4F4F;
    font-size: 15px;
}
.article__text h6 .accordion-block__item-text h6 {
    color: #4F4F4F;
    font-size: 15px;
}

.article__text span, .accordion-block__item-text span{
    color: #4F4F4F;
    font-size: 18px;
}

.article__text ul,ol {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
}
.accordion-block__item-text ul,ol {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
}
.article__text ul li{
    list-style: disc;
}
.accordion-block__item-text ul li {
    list-style: disc;
}
.article__text li, .accordion-block__item-text li{
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: justify;
    color: #4F4F4F;
}

@media(max-width:574.98px){
  .article__text li, .accordion-block__item-text li{
    font-size: 14px;
  }

}


.article__text p, .accordion-block__item-text p{
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: justify;
  color: #4F4F4F;
}

@media (max-width: 574.98px) {
  .accordion-block__item-text li  {
    font-size: 12px;
  }
  .accordion-block__item-text p  {
    font-size: 12px;
  }
  .accordion-block__item-text span  {
    font-size: 12px;
  }
}
.article__btn{
  display: none;
}
.about-us__content{
  padding: 31px 0 120px;
}
.about-us__text{
  max-width: 540px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.about-us__text span{
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #4F4F4F;
}
.about-us__text p{
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4F4F4F;
  text-align: justify;
}
.about-us__text h3{
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #4F4F4F;
}
.question__content{
  padding: 36px 0 241px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 540px;
}
.question__content .accordion-block__item.active .accordion-block__item-text{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.document__content .accordion-block__item .accordion-block__item-text{
  display: none;
}
.document__content .accordion-block__item.active .accordion-block__item-text{
  display: flex!important;
  flex-direction: column;
  gap: 25px;
}
.question__content .accordion-block__item{
  background: #F7F8F9;
  border-radius: 10px;
  padding: 18px 28px;
  overflow: hidden;
  cursor: pointer;
}
.question__content .accordion-block__item:hover{
  box-shadow: 0px 0px 3px 3px #39CCE0;
}
.question__content .accordion-block__item-title{
  font-weight: 800;
  font-size: 22px;
  line-height: 120%;
  color: #5A57C2;
}
.question__content .accordion-block__item-text{
  display: none;
}

.question__content .accordion-block__item-text p{
  font-weight: 500;
  font-size: 18px;
  line-height: 133%;
  color: #4F4F4F;
}
.question__content .accordion-block__item-text p:first-child{
  margin-top: 10px;
}
.question__content .accordion-block__item-text div:first-child{
  margin-top: 10px;
}
.question__content .accordion-block__item{
  background: #F7F8F9;
  border-radius: 10px;
  padding: 18px 28px;
  overflow: hidden;
}
.question__content .accordion-block__item{
  background: #F7F8F9;
  border-radius: 10px;
  padding: 18px 28px;
  overflow: hidden;
}
.accordion-block__item-block{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.accordion-block__item-block-title{
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #5A57C2;
}
.accordion-block__item-block-text{
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px!important;
  line-height: 17px;
  color: #4F4F4F;
}
.accordion-block__item-list{
  margin-top: 15px;
  padding-left: 20px;
}
.accordion-block__item-list li{
  list-style: disc;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2EB5B7;
}
.contact__content{
  padding: 38px 0 250px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
}
.contact__left{
  display: flex;
  flex-direction: column;
  gap: 35px;
  max-width: 450px;
}
.contact__left .contact__item:nth-child(3) span{
  margin-left: 10px;
}
.contact__item{
  display: flex;
  align-items: center;
  gap: 35px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: justify;
  color: #4F4F4F
}
.contact__right{
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 460px;
  width: 100%;
}
.contact__right-block{
  padding: 17px 20px 20px 17px;
  background: #F7F8F9;
  border-radius: 10px;
  max-width: 373px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  align-self: flex-end;
}
.contact__right-block-flex{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.contact__right-block-flex div{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.contact__right-block-flex div h5{
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #4F4F4F;
}
.contact__right-top{
  padding-right: 95px;
}
@media (max-width: 1100px) {

  .article__text{
    max-width: 450px;

  }
  .about-us__text{
    max-width: 450px;
  }
  .question__content{
    max-width: 450px;
  }
}
@media (max-width: 992px) {
  .section-top__right{
    display: none;
  }
  .article__text{
    max-width: 100%;
  }
  .about-us__text{
    max-width: 100%;
  }
  .question{
    min-height: 0;
  }
  .article{
    min-height: 0;
  }
  .about-us{
    min-height: 0;
  }
  .article__btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    width: 172px;
    height: 36px;
    background: #57B8FF;
    box-shadow: 0px 0px 1px rgba(27, 29, 34, 0.02), 0px 1px 1px rgba(27, 29, 34, 0.1);
    border-radius: 6px;
    color: #fff;
    margin-top: 15px;
  }
  .question__content{
    max-width: 100%;
  }
}
@media (max-width: 767.92px) {
  .section-top{
    border-radius: 10px;
    padding: 27px 10px 0;
  }
  .contact__content{
    flex-direction: column;
    padding: 17px 0 36px
  }
  .contact__right{
    max-width: fit-content;
  }
}
@media (max-width: 574.92px) {
  .section-top{
    height: 130px;
  }
  .section-top__bread-crumbs a, section-top__bread-crumbs span{
    font-size: 12px;
  }
  .section-top__title{
    margin-top: 11px;
    font-weight: 800;
    font-size: 16px;
    line-height: 140.4%;
  }
  .article__content{
    padding: 11px 0 75px;
  }
  .article__text{
    gap: 10px;
  }
  .about-us__text{
    gap: 10px;
  }
  .article__text p, .article__text span{
    font-size: 14px;
  }
  .about-us__content{
    padding: 11px 0 75px;
  }
  .about-us__text p{
    font-size: 14px;
  }
  .about-us__text span{
    font-size: 16px;
  }
  .about-us__text h3{
    font-size: 16px;
  }
  .question__content{
    gap: 10px;
    padding: 12px 0 85px;
  }
  .question__content .accordion-block__item{
    padding: 12px;
  }
  .question__content .accordion-block__item-title{
    font-size: 14px;
  }
  .question__content .accordion-block__item-text p{
    font-size: 12px;
  }
  .question__content{
    padding: 11px 0 98px!important;
    gap: 10px;
  }
  .accordion-block__item-block-title{
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
  .accordion-block__item-block-text{
    font-size: 12px;
    line-height: 15px;
  }
  .document__content .accordion-block__item.active .accordion-block__item-text{
    gap: 15px;
  }
  .accordion-block__item-list{
    margin-top: 5px;
    padding-left: 10px;
  }
  .accordion-block__item-list li{
    font-size: 12px;
  }
  .contact__content{
    padding: 17px 0 36px;
    gap: 32px;
  }
  .contact__item svg{
    flex: 0 0 35px;
    height: 35px;
  }
  .contact__left .contact__item:nth-child(3) span{
    margin-left: 0px;
  }
  .contact__left{
    gap: 32px;
    max-width: 100%;
  }
  .contact__right{
    gap: 17px;
  }
  .contact__right-block{
    align-self: flex-start;
  }
  .contact__item{
    font-size: 14px;
    line-height: 16px;
  }
  .contact__right-block{
    font-size: 14px;
  }
  .contact__right-block-flex{
    margin-top: 20px;
  }
  .contact__right-block-flex div h5{
    font-size: 14px;
    line-height: 16px;
  }
}
.footer__center-title{
    font-weight: 700;
    font-size: 18px;
    line-height: 144%;
    color: #FFFFFF;
    @media  (max-width: 575px) {
        font-size: 14px;
    }
}

